const questions = [
  {
    question: "Может ли зритель стать соавтором произведения?",
    answers: [
      "Может. А часто — это просто необходимо.Марсель Дюшан говорил: «Без зрителя не будет и искусства.Я даже считаю, что зритель важнее художника, поскольку он не только смотрит, но и выносит суждение».", 
      "Некоторые работы без участия зрителя в принципе невозможны. Например, перформансы Марины Абрамович, где во многих случаях именно зритель выбирает, как будет развиваться действие.",
      "Ещё один пример — работы группы Fluxus, участники которой придумывали инструкции по созданию искусства. Они хотели, чтобы каждый человек мог сделать произведение из ничего: из любого действия, жеста или мысли. С этим даже связана история знакомства Джона Леннона и сотрудничавшей с Fluxus Йоко Оно. Она подошла к нему на своей выставке и дала карточку с инструкцией: «Дыши».",
      "А в каком перформансе вы бы хотели поучаствовать?"
    ],
  },
  {
    question: "Всё, что делает художник — это искусство?",
    answers: [
      "Последние 100 лет многие художники так и считали. Например, Сальвадор Дали известен как живописец, но он никогда не ограничивал себя: снимал рекламу, моделировал одежду, писал сценарии к кино и спектаклям и проектировал здания. И всё это считают произведениями искусства Дали.", 
      "Энди Уорхол — один из самых известных художников, который превратил свою повседневность в перформанс. Кроме картин в музеях хранятся его дневники, парики, водолазки, бумажные архивы, фильмы. Также он создавал обложки альбомов для исполнителей, которые ему нравились, и теперь мы можем бесплатно видеть работы Уорхола на многих музыкальных альбомах.", 
      "А какую часть своей жизни вы бы превратили в искусство? ",
    ],
  },
  {
    question: "Как вы думаете: художник расстроится, если его работу повредить или уничтожить?",
    answers: [
      "Да, так тоже бывает: это случилось с «Девочкой с шариком» Бэнкси. Как только работу продали на аукционе, измельчитель внутри рамы разрезал картину на тонкие полоски наполовину. В итоге «Девочку» продали под новым названием — «Любовь в мусорной корзине». Так она стала стоить ещё больше.", 
      "А вот американский художник Джон Балдессари поступил более радикально. Он решил уничтожить свои работы, сделанные с 1953 по 1966, и превратил это в арт-проект. Художник сжёг картины и использовал пепел как материал для новых работ. Например, приготовил из него шоколадное печенье, расфасовал по банкам и выставил в нью-йоркском Музее современного искусства.", 
      "Представьте, что вам нужно разрушить арт-объект. Как бы вы это сделали?", 
    ],
  },
  {
    question: "Нужно ли строить музей, чтобы устроить выставку современного искусства?",
    answers: [
      "Вовсе не обязательно.Выставку можно организовать на пустыре, в мастерской или на улице.Раньше в Лондоне работали галереи, которые когда - то были общественным туалетом или полицейским участком.А сейчас есть The Crypt Gallery — она находится под землёй в бывшем склепе.",
      "Один из самых успешных современных художников Дэмиен Хёрст открыл ресторан Pharmacy. Там можно было не только поесть, но и посмотреть на его инсталляцию с полками, полными таблеток. Арт-объект был настолько успешным, что художник устроил ресторану мировое турне — Pharmacy был и в Москве.",
      "В каком неожиданном месте вы бы устроили выставку?",
    ],
  },
  {
    question: "Сон может стать произведением искусства?",
    answers: [
      "Почему нет? Именно так работали сюрреалисты, например, Рене Магритт. Он старался изобразить образы из снов максимально реалистично. В этом он следовал за дадаистами: художники движения хотели избавиться от мыслей и в своих рисунках отражали подсознательное, но к снам не обращались..",
      "А ещё вы просто можете поспать в галерее, как Тильда Суинтон в перформансе британской художницы Корнелии Паркер. Работа была вдохновлена сказкой о Спящей красавице: актриса безмятежно дремала под взглядами посетителей по восемь часов в день.",
      "Какой свой сон вы бы использовали в искусстве?",
    ],
  },
  {
    question: "Можно ли менять правила в современных выставочных пространствах?",
    answers: [
      "Иногда можно. Например, художница Сесиль Эванс поняла, что ей не нравятся аудиогиды, поэтому к ярмарке искусства Frieze сделала свой. Она изменила привычный формат: не связанные с искусством люди рассказывали о том, какие ощущения у них вызывают разные работы.",
      "Ещё в галереях может не хватать какого-то искусства. Так решил работник мюнхенской Пинакотеки, который обычно помогал развешивать картины. Однажды он пришёл и повесил свою. Привлечь внимание к творчеству не удалось: музей не поделился фотографиями работы, а мужчину уволили.",
      "А что бы вы поменяли на выставках современного искусства?",
    ],
  },
  {
    question: "Если произведение создал искусственный интеллект — это искусство?",
    answers: [
      "Конечно. Например, в 2023 году британский художник Дэвид Хокни при помощи ИИ убрал людей со своей старой картины. А потом нарисовал поверх получившегося фона новую работу «Я жил в Богемии, Богемия нормальное место».",
      "Современные технологии помогают художникам с обработкой данных, сбором информации для исследований, с набросками работ. В некоторых случаях ИИ даже становится полноценным соавтором.",
      "Что бы вы доверили ИИ, а с чем может справиться только человек?",
    ],
  },
  {
    question: "Делать искусство вместе — интереснее?",
    answers: [
      "Московские концептуальные художники считали, что сообща можно создавать искусство, которое будет сильно отличаться от сделанного в одиночку. Они  назвали свою арт-группу «Коллективные действия» и превратили походы, загородные поездки и дружеские обсуждения в произведения искусства.",
      "Коллективность может стать политическим жестом. В 2019 году молодые художники Лоуренс Абу Хамдан, Хелен Кэммок, Оскар Мурильо и Тай Шани, номинированные на престижную премию Тёрнера, объединились в группу. Они хотели показать важность совместной работы и разделить приз поровну. ",
      "С кем из близких вы бы создали арт-группу? Какой бы у неё был манифест?",
    ],
  },
  {
    question: "Музей может стать произведением искусства?",
    answers: [
      "На это рассчитывали раннесоветские художники и искусствоведы. В 1923 году Борис Шапошников написал статью «Музей как художественное произведение», а Василий Кандинский, Казимир Малевич и Александр Родченко работали над открытием Музея живописной культуры и рассматривали его как самостоятельное художественное пространство.",
      "О влиянии архитектуры на искусство вспоминают не всегда. Но такие музеи, как Центр Помпиду, Музей Гуггенхайма в Бильбао и галерея Тейт — сами по себе шедевры. В этих местах работы художников воспринимаются иначе. ",
      "Какой музей вдохновил вас больше всего?",
    ],
  },
  {
    question: "Можно ли ошибиться, создавая произведение искусства?",
    answers: [
      "Пабло Пикассо считал, что в искусстве нет ошибок. А Джексон Поллок, знаменитый полотнами из пятен краски, по некоторым свидетельствам случайно придумал свой метод. Да, просто уронил банку на холст.",
      "Уильям Миллер обнаружил, что ему достался сломанный фотоаппарат: вместо снимков он выдавал бесформенные кляксы. Но фотограф решил и дальше снимать абстракции. Сейчас в искусстве есть отдельное направление — глитч - арт, которое собирает подобные «ошибки» в цифровых пространствах.",
      "Какой музей вдохновил вас больше всего?Какие ошибки и случайности в вашей жизни оказались полезным экспериментом? ",
    ],
  },
  {
    question: "Обязательно ли идти на выставку, чтобы увидеть искусство?",
    answers: [
      "Вовсе нет. Мы можем сталкиваться с граффити ежедневно. А жителям Чикаго и Неаполя достаточно пройтись по городу, чтобы наткнуться на огромные скульптуры Аниша Капура в виде серебряного боба и стального тоннеля. В Бильбао можно увидеть украшенного цветами огромного пёсика — скульптуру Джеффа Кунса.",
      "Иногда искусство преображает привычные места. Художники Христо и Жанна-Клод оборачивали тканью всемирные достопримечательности: Рейхстаг в Берлине и Триумфальную арку в Париже. Так они предлагали по-новому взглянуть на давно знакомые вещи и столкнуться с искусством там, где его никто не ожидал.",
      "Представьте, что вам можно распоряжаться большой городской площадью. Что бы показали прохожим?",
    ],
  },
  {
    question: "Как художники могут заботиться об экологии?",
    answers: [
      "Йозеф Бойс был не только художником, но и сооснователем немецкой Зелёной партии. В рамках одной из своих акций он посадил в Касселе 7000 дубов — и поначалу жители восприняли инициативу негативно. Зато теперь, когда деревья выросли, город очень изменился.",
      "Американский художник Мел Чин пошёл ещё дальше — и сподвигнул людей сажать растения. В рамках проекта «Зеркало будущего» он разбил небольшой сад и выдал 512 зрителям список растений и инструкцию по уходу за ними.",
      "Вы бы вырастили сад в рамках арт-проекта?",
    ],
  },
  {
    question: "Должны ли художники говорить о проблемах глобального мира?",
    answers: [
      "Каждый художник сам отвечает на этот вопрос: например, Ай Вэйвэй считает, что обязаны. Многие его работы вдохновлены миграционным кризисом и поездками в центры для беженцев в Греции и Германии.",
      "Исландский художник Олафур Элиассон тоже думает, что привлечение внимания к проблемам — это его ответственность. В работе «Наблюдение за льдом» он выставил огромные куски льда из Гренландии в музеях. Так он хотел наглядно показать проблему изменения климата.",
      "А как вам кажется: искусству нужна миссия? Какую бы выбрали?",
    ],
  },
  {
    question: "Искусство помогает человеку понять себя?",
    answers: [
      "Да, разными способами. Например, предлагает зрителю оказаться в необычных обстоятельствах. Художник и архитектор Джеймс Таррелл создаёт пространства, которые залиты цветным светом, или такие, где можно наблюдать за небом. Его цель — чтобы люди остановились, подумали о вечном и заглянули в себя.",
      "А ещё перед зрителям могут поставить выбор, который расскажет о них самих. Так сделала Марина Абрамович в перформансе Rhythm 0: она позволила людям творить с ней всё, что захочется, с помощью 72 предметов. Художница проверяла, как далеко может зайти публика.",
      "Искусство помогало вам узнать что-то новое о себе?",
    ],
  },
  {
    question: "Могут ли домашние дела стать произведением искусства?",
    answers: [
      "Конечно. Например, ужин: уже много лет художник Риркрит Тиравания на перформансах готовит тайскую еду для всех гостей. Он считает, что это помогает объединять людей. А ещё его ужины — высказывание на темы миграционной политики и национальной идентичности.",
      "В 1973 году художница Мирл Ладерман Юклс пришла в музей Хартфорда и начала убираться. Она показала своим перформансом, как часто люди не замечают работников музея, кроме смотрителей. Также автор написала манифест, в котором предложила считать домашнюю работу произведением искусства — как то, что выставляют в музее.",
      "Кого бы вы пригласили на ужин, если он был выставкой?",
    ],
  },
];

export default questions;