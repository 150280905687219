import { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';

import arrowWhite from './../../media/ui/arrow[white].svg';
import styles from './InstructionCards.module.css';

// Вынести в data / google forms
const instructions = [
  <>Вместе с современными художниками предлагаем посмотреть на себя и окружающий мир под новым углом. Для этого подготовили карточки — специально к ярмарке молодого искусства.</>,
  <>Каждая карточка — это один вопрос и несколько примеров, которые помогут найти свой ответ. <br /> <br />Вы можете обсуждать их с друзьями, размышлять сами или поделиться в соцсетях.</>
];

function InstructionCards({ onFinish }) {
  const [currentCard, setCurrentCard] = useState(0); // Индекс текущей инструкции
  const [showIntro, setShowIntro] = useState(true); // Показывать ли приветственное сообщение
  const [fadeClass, setFadeClass] = useState('fadeIn'); 

   // Обработчики свайпов
  const handlers = useSwipeable({
    onSwipedUp: () => {
      if (currentCard < instructions.length - 1) {
        handleNext();
      }
    },
    onSwipedDown: () => {
      if (currentCard < instructions.length - 1) {
        handlePrevious();
      }
    },
  });

  // Отключаем скролл 
  useEffect(() => {
    const disableScroll = () => {
      document.body.style.overflowY = 'hidden'; // Отключаем скролл
    };
    const enableScroll = () => {
      document.body.style.overflowY = ''; // Восстанавливаем скролл
    };
    // Отключаем скролл при монтировании компонента
    disableScroll();
    // Восстанавливаем скролл при размонтировании компонента
    return () => enableScroll();
  }, []);

  useEffect(() => {
    // Устанавливаем анимацию при смене карточки
    setFadeClass('fadeIn');
  }, [currentCard, showIntro]);

  // Переход к следующему шагу
  const handleNext = () => {
    if (showIntro) {
      setFadeClass('fadeOut'); // Сначала анимация исчезновения
      setTimeout(() => {
        setShowIntro(false); 
        setCurrentCard(0); // Переходим к инструкциям
      }, 300); // Задержка для анимации
    } else if (currentCard < instructions.length - 1) {
      setFadeClass('fadeOut');
      setTimeout(() => setCurrentCard(currentCard + 1), 300); // Задержка для плавности
    } else {
      onFinish();
    }
  };

  // Переход к предыдущему шагу
  const handlePrevious = () => {
    if (!showIntro && currentCard > 0) {
      setFadeClass('fadeOut');
      setTimeout(() => setCurrentCard(currentCard - 1), 300);
    }
  };

  // Добавление кликов для десктопа
  const handleClick = (event) => {
    if (window.innerWidth > 768) { // Пример ширины для десктопа
      const { clientX } = event;
      const middle = window.innerWidth / 2;
      if (clientX > middle) {
        handleNext();
      } else {
        handlePrevious();
      }
    }
  };

  return (
    <div {...handlers} className={styles.instructions} onClick={handleClick}>
      {showIntro ? (
        <div className={`${styles.introCard} ${styles[fadeClass]}`}>
          <h1 className={styles.introCardHeading}>Искусство <br /> исследования </h1>
        </div>
      ) : (
        <div className={`${styles.instructionCard} ${styles[fadeClass]}`}>
          <div className={styles.instructionСardHeader}>
            <span className={styles.instructionСardHeaderCount}>{currentCard + 1} / {instructions.length}</span>
            {/* <p className={styles.instructionСardHeaderTitle}>Инструкция</p> */}
          </div>
          <div className={styles.instructionContent}>
            {instructions[currentCard]}
            {currentCard < instructions.length - 1 && (
              <div className={styles.downArrow}>
                <img src={arrowWhite} alt="Вниз!" />
              </div>
            )}
            {/* Отображаем кнопку "Начать" на последней карточке */}
            {currentCard === instructions.length - 1 && (
              <div className={styles.startButtonContainer}>
                <button onClick={onFinish}>Начать</button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default InstructionCards;