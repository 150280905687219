import React, { useState, useEffect } from 'react';
import styles from './ARScene.module.css';

const ARModelSelector = () => {
  const [answers, setAnswers] = useState({ q1: 0, q2: 0, q3: 0, q4: 0 });
  const [selectedModel, setSelectedModel] = useState(null);
  const [showAR, setShowAR] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://c.w3rlds.com/s/v/aci-viewer.js';
    script.type = 'module';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script); // Удаление скрипта при размонтировании компонента
    };
  }, []);

  const handleAnswerChange = (question, answer) => {
    setAnswers(prev => ({ ...prev, [question]: answer }));
  };

  const calculateARModel = () => {
    const combination = Object.values(answers).join('');
    setSelectedModel(getModelByCombination(combination));
    setShowAR(true);
  };


const getModelByCombination = (combination) => {
  const numericValue = parseInt(combination); // Преобразуем комбинацию в число
  // if (numericValue >= 2033 && numericValue <= 2493) return 'Fabbxm54';  // Модель 3 // !
  // if (numericValue >= 2955 && numericValue <= 3316) return 'LrmwbOvY';  // Обновлённая Модель 5
  // Математическое распределение диапазонов для 5 моделей (только коды из цифр 1, 2, и 3)
  if (numericValue >= 1111 && numericValue <= 1222) return 'bl69Cdh3';  // Модель 1
  if (numericValue >= 1223 && numericValue <= 2332) return 'Bx_ffotm';  // Модель 2
  if (numericValue >= 2333 && numericValue <= 2444) return 'Fabbxm54';  // Модель 3
  if (numericValue >= 2445 && numericValue <= 2555) return 'Zj_380Bd';  // Модель 4
  if (numericValue >= 2556 && numericValue <= 3333) return 'LrmwbOvY';  // Модель 5

  return 'bl69Cdh3'; // Значение по умолчанию, если ничего не подошло
};

  const selectedStyle = {
    backgroundColor: '#FF335F',
    borderColor: '#FF335F',
    color: 'white',
  };

  // const RadioButtons = ({ question }) => (
  //   <div className={styles.buttonsRadioContainer}>
  //     {[1, 2, 3].map((value) => (
  //       <label key={value}>
  //         <input
  //           type="radio"
  //           name={question}
  //           value={value}
  //           checked={answers[question] === value}
  //           onChange={() => handleAnswerChange(question, value)}
  //           style={{ display: 'none' }}
  //         />
  //         <span className={styles.buttonsRadio} style={{
  //           ...(answers[question] === value ? selectedStyle : {}),
  //         }}>
  //           {value}
  //         </span>
  //       </label>
  //     ))}
  //   </div>
  // );

  const RadioButtons = ({ question }) => (
  <div className={styles.buttonsRadioContainer}>
    {[1, 2, 3].map((value) => (
      <label key={value} className={styles.radioLabel}>
        <input
          type="radio"
          name={question}
          value={value}
          checked={answers[question] === value}
          onChange={() => handleAnswerChange(question, value)}
          className={styles.radioInput}
        />
        <span className={styles.buttonsRadio} style={{
          ...(answers[question] === value ? selectedStyle : {}),
        }}>
          {value}
        </span>
      </label>
    ))}
  </div>
);

  const stations = [
    { title: 'Станция 1', question: 'q1', subtitle: 'АРОМАТЫ', description: 'Какой из трёх запахов больше вам подходит?' },
    { title: 'Станция 2', question: 'q2', subtitle: 'ЗВУКИ', description: 'Какая из трёх композиций вам близка?' },
    { title: 'Станция 3', question: 'q3', subtitle: 'ОЩУЩЕНИЯ', description: 'Какое тактильное ощущение вам больше нравится?' },
    { title: 'Станция 4', question: 'q4', subtitle: 'ЗРЕНИЕ/ВКУС', description: 'Какой вкус вы больше любите? И какой визуальный образ лучше всего к нему подходит?' },
  ];

  const allQuestionsAnswered = Object.values(answers).every(answer => answer !== 0);

  return (
    <div className={styles.main}>
      <div className={styles.quizContainer}>
        <h1 className={styles.titleMain}>АНКЕТА</h1>
        
        {stations.map(({ title, subtitle, question, description }) => (
          <div className={styles.questionBlock} key={question}>
            <h2 className={styles.title}>{title}</h2>
            {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
            {description && <p className={styles.description}>{description}</p>}
            <RadioButtons question={question} />
          </div>
        ))}

        <button
          className={styles.calculateButton}
          onClick={calculateARModel}
          disabled={!allQuestionsAnswered}
        >
          Рассчитать
        </button>

        <p className={styles.disclaimer}>
          Загрузка AR сувенира может занять некоторое время в связи с ограничениями скорости загрузки провайдера в помещениях старого фонда и особенностями пространства. 
        </p>
      </div>

      <div className={styles.ARScene}>
        {showAR && selectedModel && (
          <div
            style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            dangerouslySetInnerHTML={{
              __html: `<aci-viewer contentId="${selectedModel}" style="width: 100%; height: 100%;"></aci-viewer>`,
            }}
          ></div>
        )}
      </div>
    </div>
  );
};

export default ARModelSelector;
