import React, { useState, useEffect, useRef } from 'react';
import { Copy, RefreshCw } from 'lucide-react';
import axios from 'axios';
import promoDis from "../../media/pdf/promo-dis.pdf";
import styles from './Gift.module.css';

const API_URL = 'https://sheetdb.io/api/v1/vjlpgbgpm2e4w';
const API_TOKEN = 'obxbr5kupnn3mh3jqs1jxe2zhg96c94sw9dc9rnn';

const Gift = () => {
  const [giftCode, setGiftCode] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCodeUsed, setIsCodeUsed] = useState(false);

  const giftSectionRef = useRef(null);
  const observer = useRef(null);

  // Функция для получения промокода
  const fetchGiftCode = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_URL}?used=false`, {
        headers: { Authorization: `Bearer ${API_TOKEN}` }
      });

      const codes = response.data;
      if (codes.length > 0) {
        const randomCode = codes[Math.floor(Math.random() * codes.length)];
        setGiftCode(randomCode['Промокоды']);
      }
    } catch (error) {
      console.error('Ошибка получения кода:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Функция для копирования кода и обновления статуса used
  const copyToClipboard = async () => {
    if (giftCode && !isCodeUsed) {
      try {
        await navigator.clipboard.writeText(giftCode);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
        setIsCodeUsed(true);

        const response = await axios.get(`${API_URL}?Промокоды=${giftCode}`, {
          headers: { Authorization: `Bearer ${API_TOKEN}` }
        });

        if (response.data.length > 0) {
          const codeObj = response.data[0];
          await axios.patch(`${API_URL}/id/${codeObj.id}`, {
            data: { used: true }
          }, {
            headers: { Authorization: `Bearer ${API_TOKEN}` }
          });

          console.log('Код успешно отмечен как использованный');
        }
      } catch (error) {
        console.error('Ошибка при копировании кода:', error);
      }
    }
  };

  // Обработчик IntersectionObserver
  const handleIntersection = (entries) => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      console.log('Пользователь доскроллил до блока');
      fetchGiftCode();
      observer.current.disconnect();
    }
  };

  // Инициализация IntersectionObserver
  useEffect(() => {
    if (giftSectionRef.current) {
      observer.current = new IntersectionObserver(handleIntersection, {
        root: null,
        threshold: 0.5
      });

      observer.current.observe(giftSectionRef.current);
    }

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, []);

  return (
    <footer className={styles.footer} ref={giftSectionRef}>
      <div className={styles.giftFooter}>
        <div className={styles.giftFooterInputWrapper}>
          <input
            type="text"
            value={isLoading ? 'Загрузка...' : (isCodeUsed ? 'Код скопирован' : giftCode)}
            readOnly
            className={styles.giftFooterInput}
          />
          <button 
            onClick={copyToClipboard} 
            className={styles.giftFooterCopyButton}
            disabled={isLoading || isCodeUsed}
          >
            <Copy size={20} />
          </button>
          { process.env.NODE_ENV === 'development' && (
            <button 
              onClick={fetchGiftCode} 
              className={styles.giftFooterClearButton}
              title="Получить новый код"
              disabled={isLoading}
            >
              <RefreshCw size={25} />
            </button>
          )}
        </div>
        <p className={styles.giftFooterText}>
          Даёт скидку 200 ₽ на заказ от 1200 ₽ — по 20 сентября. 
          <br />
          <br />
          Подробнее — <a target="_blank" rel="noreferrer" href={promoDis}>в правилах</a>
        </p>
      </div>
    </footer>
  );
};

export default Gift;
